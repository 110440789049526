import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    Flex,
    HStack,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from '@chakra-ui/react';

import {
    fetchUploads,
    selectOrganization,
    selectOrganizationIsLoading,
    selectUploads,
} from '../../reducers/organization';
import { useAppDispatch, useAppSelector } from '../../util/hooks';
import {
    lightGray,
    spacing1,
    spacing10,
    spacing2,
    spacing4,
    spacing6,
    spacing8,
} from '../../util/styles';
import PageHeading from '../PageHeading';
import UploadDataModal from '../UploadDataModal';
import DownloadTemplateModal from '../DownloadTemplateModal';
import { ShareType, UploadType } from '../../types/reducers/organizations';
import { selectUser } from '../../reducers/auth';
import { formatTimestamp } from '../../util/utils';
import { selectLanguage } from '../../reducers/app';

import theme from '../../theme';
import UploadDetailDrawer from '../UploadDetailDrawer';
import { find } from 'lodash';

const { orange } = theme.colors.brand;

const YourData = () => {
    const { t } = useTranslation('organizationPage');
    const { t: tu } = useTranslation('uploadStatuses');
    const dispatch = useAppDispatch();
    const organization = useAppSelector(selectOrganization);
    const uploads = useAppSelector(selectUploads);
    const user = useAppSelector(selectUser);
    const loading = useAppSelector(selectOrganizationIsLoading);
    const language = useAppSelector(selectLanguage);
    const [downloadTemplateModalOpen, setDownloadTemplateModalOpen] =
        useState(false);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedUpload, setSelectedUpload] = useState<UploadType | null>(
        null
    );

    useEffect(() => {
        if (organization) {
            dispatch(fetchUploads(organization.id));
        }
    }, [organization, dispatch]);

    useEffect(() => {
        if (selectedUpload) {
            const newUpload = find(uploads, { id: selectedUpload.id });
            if (newUpload && newUpload !== selectedUpload) {
                setSelectedUpload(newUpload);
            }
        }
    }, [uploads, selectedUpload]);

    if (!organization || !user || loading) {
        return (
            <Flex width='100%' justifyContent='center'>
                <Spinner />
            </Flex>
        );
    }

    const renderRow = (upload: UploadType) => {
        const {
            id,
            name,
            created_at: createdAt,
            created_by_email: createdBy,
        } = upload;

        const renderShares = (shares: ShareType[]) =>
            shares && shares.length > 0
                ? shares.map(share => share.shared_with).join(', ')
                : t('noOne');

        const renderStatus = (upload: UploadType) => {
            const errorText = (text: string) => (
                <Text
                    background={orange}
                    textTransform={'uppercase'}
                    borderRadius='2px'
                    padding={spacing1}
                    width={'fit-content'}
                    variant='label'
                >
                    {text}
                </Text>
            );
            if (upload.has_failure) {
                return errorText(tu(upload.status));
            }
            return <Text>{tu(upload.status)}</Text>;
        };

        return (
            <Tr
                key={id}
                sx={{
                    '&:hover': {
                        backgroundColor: lightGray,
                    },
                }}
                onClick={() => setSelectedUpload(upload)}
            >
                <Td>{name}</Td>
                <Td>
                    {user && user.email === createdBy ? t('you') : createdBy}
                </Td>
                <Td>{formatTimestamp(createdAt, language)}</Td>
                <Td>{renderShares(upload.shares)}</Td>
                <Td>{renderStatus(upload)}</Td>
            </Tr>
        );
    };

    return (
        <VStack
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='flex-start'
            gap={spacing10}
        >
            {selectedUpload && (
                <UploadDetailDrawer
                    isOpen={!!selectedUpload}
                    upload={selectedUpload}
                    onCloseCallback={() => setSelectedUpload(null)}
                />
            )}
            <DownloadTemplateModal
                isOpen={downloadTemplateModalOpen}
                onClose={() => setDownloadTemplateModalOpen(false)}
            />
            <UploadDataModal
                isOpen={uploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
            />
            <PageHeading title={t('yourData')} />
            <VStack
                padding={spacing6}
                width='100%'
                backgroundColor='white'
                gap={spacing8}
            >
                <HStack
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                >
                    <VStack gap={spacing1} alignItems='flex-start'>
                        <Text variant='headingMd'>{t('yourUploads')}</Text>
                        <Text variant='small'>
                            {t('yourUploadsDescription')}
                        </Text>
                    </VStack>
                    <HStack gap={spacing4} alignItems='center'>
                        <Button
                            variant='primary'
                            onClick={() => setDownloadTemplateModalOpen(true)}
                            // FUTURE: re-enable this once we are supporting downloading templates
                            // https://github.com/thecosaorg/cosa-frontend/issues/134
                            isDisabled={true}
                        >
                            {t('downloadTemplate')}
                        </Button>
                        <Button
                            variant='primary'
                            onClick={() => setUploadModalOpen(true)}
                        >
                            {t('uploadData')}
                        </Button>
                    </HStack>
                </HStack>

                {uploads.length > 0 ? (
                    <TableContainer
                        width='100%'
                        justifyContent='space-evenly'
                        overflowY='auto'
                    >
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th>{t('object')}</Th>
                                    <Th>{t('uploadedBy')}</Th>
                                    <Th>{t('date')}</Th>
                                    <Th>{t('sharedWith')}</Th>
                                    <Th>{t('status')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {uploads.map((u: UploadType) => renderRow(u))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Flex
                        width='100%'
                        minH='90px'
                        border={`1px solid ${lightGray}`}
                        padding={spacing2}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Text variant='small'>{t('none')}</Text>
                    </Flex>
                )}
            </VStack>
        </VStack>
    );
};

export default YourData;
