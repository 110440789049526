import {
    AnyAction,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';

import * as api from '../api';
import { RootState } from '../store';
import {
    clientStatusType,
    DatasetListType,
    membershipsListType,
    NewOrganizationParamsType,
    NewUserParamsType,
    OrganizationResponseType,
    OrganizationState,
    OrganizationType,
    organizationTypeType,
    OrganizationUpdateType,
    SharedUploadType,
    UploadType,
} from '../types/reducers/organizations';
import { ThunkType } from '../types/types';
import { getErrorMessage } from '../util/utils';
import { UserMembershipUpdateType } from '../types/reducers/auth';

const initialState: OrganizationState = {
    organization: null,
    loading: false,
    saving: false,
    error: null,
    isAdmin: null,
    members: null,
    uploads: [],
    sharedUploads: [],
    datasetList: null,
};

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        startFetchOrganization: (state: OrganizationState) => {
            state.organization = null;
            state.loading = true;
            state.error = null;
        },
        completeFetchOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<OrganizationResponseType>
        ) => {
            state.organization = payload.organization;
            state.loading = false;
            state.error = null;
            state.isAdmin = payload.is_admin;
            state.members = payload.users;
        },
        failFetchOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.organization = null;
            state.loading = false;
            state.error = payload;
        },
        startUpdateOrganization: (state: OrganizationState) => {
            state.saving = true;
            state.error = null;
        },
        completeUpdateOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<OrganizationType>
        ) => {
            state.organization = payload;
            state.saving = false;
        },
        failUpdateOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.saving = false;
            state.error = payload;
        },
        setOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<OrganizationType>
        ) => {
            state.organization = payload;
        },
        setOrganizationType: (
            state: OrganizationState,
            { payload }: PayloadAction<organizationTypeType>
        ) => {
            if (state.organization) {
                state.organization.organization_type = payload;
            }
        },
        setOrganizationClientStatus: (
            state: OrganizationState,
            { payload }: PayloadAction<clientStatusType>
        ) => {
            if (state.organization) {
                state.organization.client_status = payload;
            }
        },
        startAddNewRelatedOrganization: (state: OrganizationState) => {
            state.loading = true;
            state.error = null;
        },
        failAddNewRelatedOrganization: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = payload;
        },
        startFetchUploads: (state: OrganizationState) => {
            state.uploads = [];
            state.loading = true;
            state.error = null;
        },
        completeFetchUploads: (
            state: OrganizationState,
            { payload }: PayloadAction<UploadType[]>
        ) => {
            state.uploads = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchUploads: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.uploads = [];
            state.loading = false;
            state.error = payload;
        },
        startFetchSharedUploads: (state: OrganizationState) => {
            state.sharedUploads = [];
            state.loading = true;
            state.error = null;
        },
        completeFetchSharedUploads: (
            state: OrganizationState,
            { payload }: PayloadAction<SharedUploadType[]>
        ) => {
            state.sharedUploads = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchSharedUploads: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.sharedUploads = [];
            state.loading = false;
            state.error = payload;
        },
        startFetchDatasets: (state: OrganizationState) => {
            state.datasetList = null;
            state.loading = true;
            state.error = null;
        },
        completeFetchDatasets: (
            state: OrganizationState,
            { payload }: PayloadAction<DatasetListType>
        ) => {
            state.datasetList = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchDatasets: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.datasetList = null;
            state.loading = false;
            state.error = payload;
        },
        startAddNewUser: (state: OrganizationState) => {
            state.loading = true;
            state.error = null;
        },
        completeAddNewUser: (
            state: OrganizationState,
            { payload }: PayloadAction<membershipsListType>
        ) => {
            state.members = state.members
                ? [payload, ...state.members]
                : [payload];
            state.loading = false;
            state.error = null;
        },
        failAddNewUser: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = payload;
        },
        startUpdateUserMembership: (state: OrganizationState) => {
            state.loading = true;
            state.error = null;
        },
        completeUpdateUserMembership: (
            state: OrganizationState,
            { payload }: PayloadAction<membershipsListType[]>
        ) => {
            state.members = payload;
            state.loading = false;
            state.error = null;
        },
        failUpdateUserMembership: (
            state: OrganizationState,
            { payload }: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const {
    startFetchOrganization,
    completeFetchOrganization,
    failFetchOrganization,
    startUpdateOrganization,
    completeUpdateOrganization,
    failUpdateOrganization,
    setOrganization,
    setOrganizationType,
    setOrganizationClientStatus,
    startAddNewRelatedOrganization,
    failAddNewRelatedOrganization,
    startFetchUploads,
    completeFetchUploads,
    failFetchUploads,
    startFetchSharedUploads,
    completeFetchSharedUploads,
    failFetchSharedUploads,
    startFetchDatasets,
    completeFetchDatasets,
    failFetchDatasets,
    startAddNewUser,
    completeAddNewUser,
    failAddNewUser,
    startUpdateUserMembership,
    completeUpdateUserMembership,
    failUpdateUserMembership,
} = organizationSlice.actions;

export const fetchOrganization =
    (id?: string): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchOrganization());
        try {
            if (id) {
                const data = await api.getOrganization(id);
                dispatch(completeFetchOrganization(data));
            } else {
                const data = await api.getUserOrganization();
                dispatch(completeFetchOrganization(data));
            }
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to fetch organization.'
            );

            dispatch(failFetchOrganization(message));
        }
    };

export const updateOrganization =
    (
        id: string,
        organization: OrganizationUpdateType,
        onFailure?: () => void,
        onSuccess?: () => void
    ): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startUpdateOrganization());
        try {
            const data = await api.putOrganization(id, organization);
            dispatch(completeUpdateOrganization(data));
            if (onSuccess) {
                onSuccess();
            }
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to update organization.'
            );
            dispatch(failUpdateOrganization(message));
            if (onFailure) {
                onFailure();
            }
        }
    };

export const addRelatedOrganization =
    (
        params: NewOrganizationParamsType,
        id: string,
        onFailure?: () => void,
        onSuccess?: () => void
    ): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startAddNewRelatedOrganization());
        try {
            await api.postNewRelatedOrganization(params, id);
            // Refetch organization for new related orgs list
            // Instead of patching existing state
            dispatch(fetchOrganization(id));
            if (onSuccess) {
                onSuccess();
            }
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to add new organization.'
            );

            dispatch(failAddNewRelatedOrganization(message));
            if (onFailure) {
                onFailure();
            }
        }
    };

export const fetchUploads =
    (id: string): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchUploads());
        try {
            const data = await api.getUploads(id);
            dispatch(completeFetchUploads(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to fetch uploads.'
            );

            dispatch(failFetchUploads(message));
        }
    };

export const fetchSharedUploads =
    (id: string): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchSharedUploads());
        try {
            const data = await api.getSharedUploads(id);
            dispatch(completeFetchSharedUploads(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to fetch shared uploads.'
            );

            dispatch(failFetchSharedUploads(message));
        }
    };

export const fetchDatasets =
    (id: string, page: number = 1, pageSize: number = 10): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchDatasets());
        try {
            const data = await api.getDatasets(id, page, pageSize);
            dispatch(completeFetchDatasets(data || null));
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to fetch uploads.'
            );

            dispatch(failFetchDatasets(message));
        }
    };

export const addUser =
    (
        organizationId: string,
        params: NewUserParamsType,
        onSuccess: () => void,
        onFailure: () => void
    ): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startAddNewUser());
        try {
            const data = await api.postNewUser(organizationId, params);
            dispatch(completeAddNewUser(data));
            onSuccess();
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to add new organization.'
            );
            onFailure();
            dispatch(failAddNewUser(message));
        }
    };

export const updateUserMembership =
    (
        organizationId: string,
        membershipId: string,
        params: UserMembershipUpdateType,
        onSuccess: () => void,
        onFailure: () => void
    ): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startUpdateUserMembership());
        try {
            const data = await api.putUserMembership(
                organizationId,
                membershipId,
                params
            );
            dispatch(completeUpdateUserMembership(data));
            onSuccess();
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to update user.'
            );
            dispatch(failUpdateUserMembership(message));
            onFailure();
        }
    };

export const selectOrganization = (state: RootState) =>
    state.organization.organization;
export const selectIsAdmin = (state: RootState) => state.organization.isAdmin;
export const selectOrganizationMembers = (state: RootState) =>
    state.organization.members;

export const selectOrganizationIsLoading = (state: RootState) =>
    state.organization.loading;
export const selectUploads = (state: RootState) => state.organization.uploads;
export const selectSharedUploads = (state: RootState) =>
    state.organization.sharedUploads;

export const selectDatasets = (state: RootState) =>
    state.organization.datasetList;

export default organizationSlice.reducer;
