import {
    CreateUploadType,
    DatasetListType,
    membershipsListType,
    NewOrganizationParamsType,
    NewUserParamsType,
    OrganizationResponseType,
    OrganizationType,
    OrganizationUpdateType,
    SharedUploadType,
    UploadType,
} from './types/reducers/organizations';
import {
    LoginType,
    UserMembershipUpdateType,
    UserType,
    UserUpdateType,
} from './types/reducers/auth';
import axios from 'axios';
import { URLs } from './util/constants';
import { fetchTimeoutMs } from './env';

axios.defaults.baseURL = '/api';
// Used to make authenticated HTTP requests to Django
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
export const API = axios.create({
    headers: {
        credentials: 'same-origin',
    },
});

export const getOrganizations = async (): Promise<OrganizationType[]> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeOrganizationsURL();
        API.get(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error retrieving ${url}: ${error}`));
    });
};

export const getOrganization = async (
    id: string
): Promise<OrganizationResponseType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeOrganizationURL(id);
        API.get(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error retrieving ${url}: ${error}`));
    });
};

export const getUserOrganization =
    async (): Promise<OrganizationResponseType> => {
        return new Promise((resolve, reject) => {
            const url = URLs.makeUserOrganizationURL();
            API.get(url, { timeout: fetchTimeoutMs })
                .then(response => response.data)
                .then(resolve)
                .catch(error => reject(`Error retrieving ${url}: ${error}`));
        });
    };

export async function getLoginWithSessionId(): Promise<LoginType> {
    return API.get(URLs.makeLoginURL()).then(response => response.data);
}

export const postNewOrganization = async (
    params: NewOrganizationParamsType
): Promise<OrganizationType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeNewOrganizationURL();
        API.post(url, { ...params, timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error sending ${url}: ${error}`));
    });
};

export const postNewUser = async (
    organizationId: string,
    params: NewUserParamsType
): Promise<membershipsListType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeNewUserURL(organizationId);
        API.post(url, { ...params, timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error sending ${url}: ${error}`));
    });
};

export const disableOrganization = async (
    id: string
): Promise<OrganizationType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeDisableOrganizationURL(id);
        API.put(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error disabling organization: ${error}`));
    });
};

export const postNewRelatedOrganization = async (
    params: NewOrganizationParamsType,
    id: string
): Promise<OrganizationType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeNewRelatedOrganizationURL(id);
        API.post(url, { ...params, timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error sending ${url}: ${error}`));
    });
};

export async function updateUser(
    id: string,
    data: UserUpdateType
): Promise<UserType> {
    return API.put(URLs.makeUpdateUserURL(id), data).then(
        response => response.data
    );
}

export async function putUserMembership(
    organizationId: string,
    membershipId: string,
    data: UserMembershipUpdateType
): Promise<membershipsListType[]> {
    return API.put(
        URLs.makeUpdateMembershipURL(organizationId, membershipId),
        data
    ).then(response => response.data);
}

export async function putOrganization(
    id: string,
    data: OrganizationUpdateType
): Promise<OrganizationType> {
    return API.put(URLs.makeUpdateOrganizationURL(id), data).then(
        response => response.data
    );
}

export const getUploads = async (id: string): Promise<UploadType[]> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeUploadsURL(id);
        API.get(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error retrieving ${url}: ${error}`));
    });
};

export const getSharedUploads = async (
    id: string
): Promise<SharedUploadType[]> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeSharedUploadsURL(id);
        API.get(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error retrieving ${url}: ${error}`));
    });
};

export const getDatasets = async (
    id: string,
    page: number,
    pageSize: number
): Promise<DatasetListType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeDatasetsURL(id, page, pageSize);
        API.get(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error retrieving ${url}: ${error}`));
    });
};

export const createUpload = async (
    id: string,
    data: CreateUploadType,
    onSuccess?: () => void,
    onFailure?: () => void
): Promise<UploadType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeUploadURL(id);
        API.post(url, data)
            .then(response => response.data)
            .then(resolve)
            .catch(error =>
                reject(`Error uploading ${data.original_filename}: ${error}`)
            );
    });
};

export const completeUpload = async (
    org_id: string,
    upload_id: string
): Promise<UploadType> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeCompleteUploadURL(org_id, upload_id);
        API.post(url)
            .then(response => response.data)
            .then(resolve)
            .catch(error =>
                reject(`Error completing upload ${upload_id}: ${error}`)
            );
    });
};

export const sendInvitation = async (
    organizationId: string,
    membershipId: string
): Promise<void> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeSendInvitationURL(organizationId, membershipId);
        API.post(url, null, { timeout: fetchTimeoutMs })
            .then(() => resolve())
            .catch(error => reject(`Error POSTing to ${url}: ${error}`));
    });
};

export const shareUpload = async (
    organizationId: string,
    uploadId: string,
    sharedWithId: string
): Promise<UploadType[]> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeShareUploadURL(organizationId, uploadId);
        API.post(
            url,
            { share_with_organization_id: sharedWithId },
            { timeout: fetchTimeoutMs }
        )
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error POSTing to ${url}: ${error}`));
    });
};

export const unshareUpload = async (
    organizationId: string,
    uploadId: string,
    shareId: string
): Promise<UploadType[]> => {
    return new Promise((resolve, reject) => {
        const url = URLs.makeUnshareUploadURL(
            organizationId,
            uploadId,
            shareId
        );
        API.delete(url, { timeout: fetchTimeoutMs })
            .then(response => response.data)
            .then(resolve)
            .catch(error => reject(`Error DELETEing ${url}: ${error}`));
    });
};
