const makeMembershipUrl = (organizationId: string, membershipId: string) =>
    `/organization/${organizationId}/membership/${membershipId}/`;

const makeUploadDetailUrl = (org_id: string, upload_id: string) =>
    `/organization/${org_id}/upload/${upload_id}/`;

export const URLs = {
    makeLoginURL: () => '/auth/login/',
    makeBrowserURL: (path: string) =>
        `${window.location.protocol}//${window.location.host}/${path}`,
    makeOrganizationsURL: () => '/organizations/',
    makeOrganizationURL: (id: string) => `/organizations/${id}/`,
    makeUserOrganizationURL: () => `/organization/`,
    makeNewOrganizationURL: () => `/organization/register/`,
    makeNewUserURL: (organizationId: string) =>
        `/organization/${organizationId}/membership/`,
    makeUpdateMembershipURL: makeMembershipUrl,
    makeSendInvitationURL: (organizationId: string, membershipId: string) =>
        `${makeMembershipUrl(organizationId, membershipId)}send-invitation/`,
    makeDisableOrganizationURL: (id: string) => `/organization/${id}/disable/`,
    makeNewRelatedOrganizationURL: (id: string) =>
        `organization/${id}/related/`,
    makeUpdateUserURL: (id: string) => `/user/${id}/`,
    makeUpdateOrganizationURL: (id: string) => `/organization/${id}/`,
    makeUploadsURL: (id: string) => `/organization/${id}/uploads/`,
    makeSharedUploadsURL: (id: string) => `/organization/${id}/shared-uploads/`,
    makeDatasetsURL: (id: string, page: number, pageSize: number) =>
        `/organization/${id}/datasets/?page=${page}&page_size=${pageSize}`,
    makeUploadURL: (id: string) => `/organization/${id}/upload/`,
    makeCompleteUploadURL: (org_id: string, upload_id: string) =>
        `${makeUploadDetailUrl(org_id, upload_id)}complete-upload/`,
    makeShareUploadURL: (org_id: string, upload_id: string) =>
        `${makeUploadDetailUrl(org_id, upload_id)}share/`,
    makeUnshareUploadURL: (
        org_id: string,
        upload_id: string,
        share_id: string
    ) => `${makeUploadDetailUrl(org_id, upload_id)}share/${share_id}/`,
};

export const Paths = {
    login: '/',
    landing: '/landing/',
    organizations: '/organizations/',
    organization: '/organizations/:id/',
    orgNetwork: '/organizations/:id/network/',
    orgUsers: '/organizations/:id/users/',
    orgData: '/organizations/:id/data/',
    users: '/users/',
    profile: '/profile/',
    permissions: '/permissions/',
    privacy: '/privacy/',
    djangoAdmin: '/admin/',
};

export const organizationTypeOptions = ['CUSTOMER_SUPPLIER', 'AUDITOR'];
export const clientStatusOptions = ['ACTIVE', 'INACTIVE'];

// Organization types
export const CUSTOMER_SUPPLIER = 'CUSTOMER_SUPPLIER';
export const AUDITOR = 'AUDITOR';

export const CUSTOMER = 'CUSTOMER';
export const SUPPLIER = 'SUPPLIER';

// Client status
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

// Tabs
export const HOME = 'HOME';
export const DASHBOARDS = 'DASHBOARDS';
export const YOUR_DATA = 'YOUR_DATA';
export const SHARED_DATA = 'SHARED_DATA';
export const NETWORK = 'NETWORK';
export const PROFILE = 'PROFILE';
export const USERS = 'USERS';

// Parent tabs
export const SETTINGS = 'SETTINGS';
export const DATA = 'DATA';

// Membership roles
export const ADMIN = 'ADMIN';
export const READ_ONLY = 'READ_ONLY';
export const USER = 'USER';

// CustomerSupplier Status roles
export const PENDING = 'PENDING';
export const ACCEPTED = 'ACCEPTED';
export const REJECTED = 'REJECTED';

// Upload statuses
export const WAITING = 'WAITING';
export const COMPLETE = 'COMPLETE';
export const ERROR = 'ERROR';
