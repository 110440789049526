import { useEffect, useState } from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    Link,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from '../util/hooks';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';

import {
    OrganizationType,
    RelatedOrganizationType,
    ShareType,
    UploadType,
} from '../types/reducers/organizations';
import { spacing1, spacing2, spacing4, spacing6 } from '../util/styles';
import { selectLanguage } from '../reducers/app';
import * as api from '../api';

import theme from '../theme';
import { RiCloseFill } from 'react-icons/ri';
import { formatTimestamp } from '../util/utils';
import {
    completeFetchUploads,
    fetchUploads,
    selectOrganization,
} from '../reducers/organization';
import { use } from 'i18next';

const { lightGray, orange } = theme.colors.brand;
interface ModalProps {
    isOpen: boolean;
    upload: UploadType;
    onCloseCallback: () => void;
}

interface ShareListType {
    share_id?: string;
    shared_with_id: string;
    shared_with: string;
}

const relatedOrgsToShares = (
    orgs: RelatedOrganizationType[] | undefined,
    excludeIds: Set<string>
): ShareListType[] =>
    orgs
        ?.filter(
            (o: RelatedOrganizationType) => !excludeIds.has(o.related_org_id)
        )
        .map((o: RelatedOrganizationType) => ({
            share_id: undefined,
            shared_with_id: o.related_org_id,
            shared_with: o.related_org_name,
        })) || [];

const UploadDetailDrawer = ({
    isOpen,
    upload,
    onCloseCallback,
}: ModalProps) => {
    const { t } = useTranslation('organizationPage');
    const { t: tc } = useTranslation('common');
    const language = useAppSelector(selectLanguage);
    const organization = useAppSelector(selectOrganization);
    const dispatch = useAppDispatch();
    const toast = useToast();

    const [changingShareForOrgId, setChangingShareForOrgId] = useState<
        string | undefined
    >(undefined);

    const [shares, setShares] = useState<ShareListType[]>([]);
    useEffect(() => {
        // Create a list of ShareListType objects that is a combination of all
        // organizations in the network combined with all the shares. If share_id
        // is not undefined that means that the upload has been shared with that
        // organization.
        const sharedWithIds = new Set(
            upload?.shares?.map(({ shared_with_id }) => shared_with_id)
        );
        const activeShares: ShareListType[] = upload.shares.map(share => ({
            share_id: share.id,
            shared_with_id: share.shared_with_id,
            shared_with: share.shared_with,
        }));
        const customers = relatedOrgsToShares(
            organization?.customers,
            sharedWithIds
        );
        const suppliers = relatedOrgsToShares(
            organization?.suppliers,
            sharedWithIds
        );
        setShares(
            sortBy(
                [...activeShares, ...customers, ...suppliers],
                ['sharedWith']
            )
        );
    }, [upload, organization?.suppliers, organization?.customers]);

    const downloadValidationNotes = () => {
        if (upload.get_validation_url) {
            const link = document.createElement('a');
            link.href = upload.get_validation_url;
            document.body.appendChild(link);
            try {
                link.click();
            } finally {
                document.body.removeChild(link);
            }
        }
    };
    if (!organization) {
        return <Spinner />;
    }
    return (
        <Drawer
            placement='right'
            size='md'
            variant='profile'
            isOpen={isOpen}
            onClose={onCloseCallback}
        >
            <DrawerOverlay />
            <DrawerContent padding={spacing6}>
                <DrawerHeader paddingLeft='0px' paddingRight='0px'>
                    <HStack
                        width='100%'
                        height='40px'
                        alignItems='center'
                        justifyContent='space-between'
                        paddingLeft='0px'
                    >
                        <Text variant='headingMd'>{upload.name}</Text>
                        <Flex
                            width='34px'
                            height='34px'
                            alignItems='center'
                            justifyContent='center'
                            onClick={onCloseCallback}
                            borderRadius='4px'
                            _hover={{
                                background: lightGray,
                            }}
                        >
                            <RiCloseFill height='24px' />
                        </Flex>
                    </HStack>
                </DrawerHeader>
                <DrawerBody padding='0px' height='100%'>
                    <VStack gap={spacing4} paddingY={spacing2}>
                        <VStack
                            width='100%'
                            alignItems='flex-start'
                            gap={spacing1}
                        >
                            {upload.get_url && (
                                <Link
                                    variant='underline'
                                    download={upload.original_filename}
                                    href={upload.get_url}
                                >
                                    {`${tc('download')} ${
                                        upload.original_filename
                                    }`}
                                </Link>
                            )}
                        </VStack>
                        {upload.get_validation_url && (
                            <VStack
                                display='flex'
                                alignItems='flex-start'
                                width='100%'
                                backgroundColor={'rgba(241, 136, 4, 0.05)'}
                                padding={spacing2}
                                gap={spacing2}
                                borderRadius='4px'
                            >
                                <Text variant='headingSm'>
                                    {t(upload.status)}
                                </Text>
                                <Text
                                    color={orange}
                                    fontSize='14px'
                                    fontStyle='normal'
                                    fontWeight='400'
                                    lineHeight='150%'
                                >
                                    {t(
                                        'Download validation notes to learn more.'
                                    )}
                                </Text>
                                <Button
                                    width='100%'
                                    height='40px'
                                    onClick={e => {
                                        e.preventDefault();
                                        downloadValidationNotes();
                                    }}
                                >
                                    <Text variant={'md'}>
                                        {t('Download validation notes')}
                                    </Text>
                                </Button>
                            </VStack>
                        )}
                        <Text alignSelf='flex-start' variant='headingSm'>
                            {t('information')}
                        </Text>
                        <HStack width='100%'>
                            <VStack
                                width='50%'
                                alignItems='flex-start'
                                gap={spacing1}
                            >
                                <Text variant='small'>{t('addedBy')}</Text>
                                <Text variant='small'>
                                    {upload.created_by_email}
                                </Text>
                            </VStack>
                            <VStack
                                width='50%'
                                alignItems='flex-start'
                                gap={spacing1}
                            >
                                <Text variant='small'>{t('date')}</Text>
                                <Text variant='small'>
                                    {formatTimestamp(
                                        upload.created_at,
                                        language
                                    )}
                                </Text>
                            </VStack>
                        </HStack>
                        <Text alignSelf='flex-start' variant='headingSm'>
                            {t('shareFileWithYourNetwork')}
                        </Text>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>{t('share')}</Th>
                                    <Th>{t('organization')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {shares.map((share: ShareListType) => {
                                    return (
                                        <Tr>
                                            <Td>
                                                {changingShareForOrgId ===
                                                share.shared_with_id ? (
                                                    <Spinner size='xs' />
                                                ) : (
                                                    <input
                                                        type='checkbox'
                                                        checked={
                                                            !!share.share_id
                                                        }
                                                        onClick={async () => {
                                                            setChangingShareForOrgId(
                                                                share.shared_with_id
                                                            );
                                                            if (
                                                                !!share.share_id
                                                            ) {
                                                                try {
                                                                    const uploads =
                                                                        await api.unshareUpload(
                                                                            organization.id,
                                                                            upload.id,
                                                                            share.share_id
                                                                        );
                                                                    dispatch(
                                                                        completeFetchUploads(
                                                                            uploads
                                                                        )
                                                                    );
                                                                } catch {
                                                                    toast({
                                                                        position:
                                                                            'top',
                                                                        title: t(
                                                                            'failure'
                                                                        ),
                                                                        description:
                                                                            t(
                                                                                'failedToUnshareUpload'
                                                                            ),
                                                                        status: 'error',
                                                                        duration: 3000,
                                                                        isClosable:
                                                                            true,
                                                                    });
                                                                } finally {
                                                                    setChangingShareForOrgId(
                                                                        undefined
                                                                    );
                                                                }
                                                            } else {
                                                                try {
                                                                    const uploads =
                                                                        await api.shareUpload(
                                                                            organization.id,
                                                                            upload.id,
                                                                            share.shared_with_id
                                                                        );
                                                                    dispatch(
                                                                        completeFetchUploads(
                                                                            uploads
                                                                        )
                                                                    );
                                                                } catch {
                                                                    toast({
                                                                        position:
                                                                            'top',
                                                                        title: t(
                                                                            'failure'
                                                                        ),
                                                                        description:
                                                                            t(
                                                                                'failedToShareUpload'
                                                                            ),
                                                                        status: 'error',
                                                                        duration: 3000,
                                                                        isClosable:
                                                                            true,
                                                                    });
                                                                } finally {
                                                                    setChangingShareForOrgId(
                                                                        undefined
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Td>
                                            <Td>{share.shared_with}</Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                        <VStack
                            padding='8px'
                            backgroundColor={'rgba(241, 136, 4, 0.05)'}
                        >
                            <Text
                                color={orange}
                                fontSize='14px'
                                fontStyle='normal'
                                fontWeight='400'
                                lineHeight='150%'
                            >
                                {t('shareAcknowledgement')}
                            </Text>
                        </VStack>
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default UploadDetailDrawer;
